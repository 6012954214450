@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-taipa-yellow: #ffdd00;
  --color-taipa-green: #93d500;
  --color-taipa-red: #fe3b1f;
  --color-taipa-dark-blue: #004876;
  --color-taipa-light-blue: #10cfc9;
  --color-taipa-black: #0c0b06;
  --color-taipa-gray: #e6e3db;

  --color-coloane-yellow: #FCBD30;
  --color-coloane-green: #2F8345;
  --color-coloane-red: #F12922;
  --color-coloane-blue: #1D419F;
  --color-coloane-black: #0C0B06;
  --color-coloane-gray: #E6E3DB;

  --color-rua-red: #CF3339;
  --color-rua-black: #222222;
  --color-rua-gray: #E6E3DB;
}

html,
body {
  font-family: "obvia", "Noto Sans HK", "Noto Sans SC", system-ui, -apple-system,
    /* Firefox supports this but not yet `system-ui` */ "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

a {
  color: var(--color-taipa-dark-blue);
}
